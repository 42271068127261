import React, { useState } from "react";
import PropTypes from "prop-types";
import { ChevronRightIcon } from "@heroicons/react/solid";
import classNames from "../../utils/classnames";
import { StarIcon } from "@heroicons/react/outline";
import { updateFavoriteEngagement } from "../../services/userServices"; // Import du service pour mettre à jour les favoris
import { useAuth } from "../../contexts/AuthContext";

const statuses = {
  offline: "text-gray-500 bg-gray-100/10",
  online: "text-green-400 bg-green-400/10",
  error: "text-rose-400 bg-rose-400/10",
};
const environments = {
  Brouillon: "text-gray-400 bg-gray-400/10 ring-gray-400/20",
  En_cours: "text-indigo-400 bg-indigo-400/10 ring-indigo-400/30",
  Suspendu: "text-red-400 bg-red-400/10 ring-red-400/30",
  Archivé: "text-red-400 bg-red-400/10 ring-red-400/30",
  Signé: "text-green-400 bg-green-400/10 ring-green-400/30",
  En_attente: "text-amber-400 bg-amber-400/10 ring-amber-400/30",
};

const EngagementListItem = ({ engagement }) => {
  const { currentUser } = useAuth();
  const [hoveredEngagement, setHoveredEngagement] = useState(false);
  const [isFavorite, setIsFavorite] = useState(
    currentUser.favoriteEngagements?.includes(engagement.id),
  );
  const [path, setPath] = useState(window.location.pathname);

  const handleFavoriteToggle = async (e) => {
    e.preventDefault();
    try {
      await updateFavoriteEngagement(currentUser.uid, engagement.id);
      setIsFavorite(!isFavorite);
    } catch (error) {
      console.error("Failed to update favorite engagement: ", error);
    }
  };

  return (
    <a key={engagement.id} href={"/engagement/" + engagement.id}>
      <li
        className="relative flex items-center space-x-4 px-4 py-4 sm:px-6 lg:px-8"
        onMouseEnter={() => setHoveredEngagement(true)}
        onMouseLeave={() => setHoveredEngagement(false)}
      >
        {path.includes("engagements") && (
          <div className="w-6 h-6">
            {isFavorite && (
              <StarIcon
                className="w-5 h-5 cursor-pointer text-yellow-300"
                onClick={handleFavoriteToggle}
              />
            )}

            {hoveredEngagement && !isFavorite && (
              <StarIcon
                className="w-5 h-5 cursor-pointer text-gray-300 hover:text-yellow-300"
                onClick={handleFavoriteToggle}
              />
            )}
          </div>
        )}
        <div className="min-w-0 flex-auto">
          <div className="flex items-center gap-x-3">
            <div
              className={classNames(
                statuses["online"],
                "flex-none rounded-full p-1",
              )}
            >
              <div className="h-2 w-2 rounded-full bg-current" />
            </div>
            <h2 className="min-w-0 text-sm font-semibold leading-6 text-white">
              <div className="flex gap-x-2">
                <span className="truncate font-light text-gray-200">
                  {engagement.client.nom}
                </span>
                <span className="text-gray-400">/</span>
                <span className="whitespace-nowrap">{engagement.type}</span>
              </div>
            </h2>
          </div>
          <div className="mt-3 flex items-center gap-x-2.5 text-xs leading-5 text-gray-400">
            <p className="truncate">{engagement.description}</p>
            <svg
              viewBox="0 0 2 2"
              className="h-0.5 w-0.5 flex-none fill-gray-300"
            >
              <circle cx={1} cy={1} r={1} />
            </svg>
            <p className="whitespace-nowrap">
              Modifié le {engagement.last_modified.toLocaleString()}
            </p>
            <svg
              viewBox="0 0 2 2"
              className="h-0.5 w-0.5 flex-none fill-gray-300"
            >
              <circle cx={1} cy={1} r={1} />
            </svg>
            <p className="whitespace-nowrap">
              Créé par{" "}
              {engagement.creator.name + " " + engagement.creator.firstname}
            </p>
          </div>
        </div>
        <div
          className={classNames(
            environments[engagement.state?.replace(/ /g, "_") || "Brouillon"],
            "rounded-full flex-none py-1 px-2 text-xs font-medium ring-1 ring-inset",
          )}
        >
          {engagement.state}
        </div>
        <ChevronRightIcon
          className="h-5 w-5 flex-none text-gray-400"
          aria-hidden="true"
        />
      </li>
    </a>
  );
};

EngagementListItem.propTypes = {
  engagement: PropTypes.object.isRequired,
};

export default EngagementListItem;
