import { React, useEffect, useState } from "react";

import { getAllUsers, createUser } from "../../services/userServices";

export default function dashboardsection() {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      const response = await getAllUsers();
      setUsers(response);
    };

    fetchUsers();
  }, []);

  function handleSubmit(event) {
    event.preventDefault();
    const formData = new FormData(event.target);
    const name = formData.get("name");
    const firstname = formData.get("firstname");
    const email = formData.get("email");
    const organisationId = formData.get("organisationId");

    createUser({ name, firstname, email, organisationId });
  }

  return (
    <>
      <div className="flex w-full h-16 p-6">
        <h1 className="text-4xl font-bold leading-7 text-white">Dashboard</h1>
      </div>
      <div className="flex w-full">
        <div className="w-2/3 p-4">
          {/* Liste des users */}
          <div className="bg-gray-900">
            <div className="mx-auto max-w-7xl">
              <div className="bg-gray-900 py-10">
                <div className="px-4 sm:px-6 lg:px-8">
                  <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                      <h1 className="text-base font-semibold leading-6 text-white">
                        Utilisateurs
                      </h1>
                      <p className="mt-2 text-sm text-gray-300">
                        Liste des utilisateurs firebase
                      </p>
                    </div>
                  </div>
                  <div className="mt-8 flow-root">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table className="min-w-full divide-y divide-gray-700">
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-0"
                              >
                                Nom
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                              >
                                Prénom
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                              >
                                Email
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                              >
                                Organisation
                              </th>

                              <th
                                scope="col"
                                className="relative py-3.5 pl-3 pr-4 sm:pr-0"
                              >
                                <span className="sr-only">Edit</span>
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-800">
                            {users.map((user) => (
                              <tr key={user.id}>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-white sm:pl-0">
                                  {user.name}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                                  {user.firstname}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                                  {user.email}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                                  {user.organisationName}
                                </td>

                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                  <a
                                    href="#"
                                    className="text-indigo-400 hover:text-indigo-300"
                                  >
                                    Edit
                                    <span className="sr-only">
                                      , {user.name}
                                    </span>
                                  </a>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-1/3 h-96 p-2">
          {/* Add user form */}
          <form className="w-full p-6" onSubmit={handleSubmit}>
            <h2 className="text-2xl font-bold leading-7 text-white">
              Ajouter un utilisateur
            </h2>
            <div className="flex flex-col gap-y-4 mt-4">
              <input
                type="text"
                name="name"
                placeholder="Nom"
                className="p-2 rounded-md bg-gray-800 text-white"
                required
              />
              <input
                type="text"
                name="firstname"
                placeholder="Prénom"
                className="p-2 rounded-md bg-gray-800 text-white"
                required
              />
              <input
                type="email"
                name="email"
                placeholder="Email"
                className="p-2 rounded-md bg-gray-800 text-white"
                required
              />

              {/* Selection de l'organisation */}
              <select className="p-2 rounded-md bg-gray-800 text-white">
                <option value="">Choisir une organisation</option>
                <option value="1">Organisation 1</option>
                <option value="2">Organisation 2</option>
                <option value="3">Organisation 3</option>
              </select>

              <button
                type="submit"
                className="p-2 bg-blue-500 text-white rounded-md"
              >
                Ajouter
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
