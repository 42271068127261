import React, { useRef, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/images/logo.svg";
import google_icon from "../assets/images/google_icon.svg";
import { Input, Label, Button, SuccessAlert } from "../components/connexion";
import { useAuth } from "../contexts/AuthContext";
import { getAuth, sendPasswordResetEmail } from "firebase/auth"; // Importer la méthode Firebase
import { ErrorAlert } from "../components";

const Connexion = () => {
  const emailRef = useRef();
  const passwordRef = useRef();
  const { login, currentUser, resetPassword } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [isSent, setIsSent] = useState(false);

  // Redirect to home if user is already logged in
  useEffect(() => {
    if (currentUser) {
      navigate("/");
    }
  }, [currentUser, navigate]);

  // login
  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setError("");
      setLoading(true);
      await login(emailRef.current.value, passwordRef.current.value);
      navigate("/");
    } catch (error) {
      setError("Erreur de connexion. Email ou mot de passe incorrect"); // Ajouter un message d'erreur
    }
    setLoading(false);
  }

  // forgotten password
  const handleForgottenPassword = async () => {
    try {
      // Vérifier si l'email est vide. Si c'est le cas, afficher une erreur
      if (emailRef.current.value === "") {
        setError("Veuillez saisir une adresse email.");
        return;
      }
      await resetPassword(emailRef.current.value); // Utilisez la fonction depuis le contexte
      setError("");
      setIsSent(true);
    } catch (error) {
      setError("Erreur lors de l'envoi de l'email de réinitialisation.");
      console.error("Erreur !");
    }
  };

  // Fonction pour fermer l'alerte de succès
  const handleDismiss = () => {
    setIsSent(false);
  };

  const onDismiss = () => {
    setError("");
  };

  return (
    <>
      {isSent && (
        <SuccessAlert
          onDismiss={handleDismiss}
          text="Un email de réinitialisation de mot de passe a été envoyé."
        />
      )}

      {error != "" && <ErrorAlert text={error} onDismiss={onDismiss} />}

      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        {/* HEADER */}
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img className="mx-auto h-28 w-auto" src={logo} alt="Oditum" />
          <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-white">
            Connectez-vous à votre compte
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <Label label={"Adresse email"} />
              <Input
                type={"email"}
                id={"email"}
                name={"email"}
                ref={emailRef}
                required
              />
            </div>
            <div>
              <Label label={"Mot de passe"} />
              <Input
                type={"password"}
                id={"password"}
                name={"password"}
                ref={passwordRef}
                required
              />
            </div>
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  className="h-4 w-4 rounded bg-slate-900 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                />
                <label
                  htmlFor="remember-me"
                  className="ml-3 block text-sm leading-6 text-white"
                >
                  Se souvenir de moi
                </label>
              </div>
              <div className="text-sm leading-6">
                <button
                  type="button" // Changer en button pour éviter le comportement de lien
                  className="font-semibold text-indigo-600 hover:text-indigo-500"
                  onClick={handleForgottenPassword} // Appeler la fonction ici
                >
                  Mot de passe oublié ?
                </button>
              </div>
            </div>
            <div>
              <Button
                type="submit"
                className="flex w-full justify-center bg-indigo-500 hover:bg-indigo-400 focus-visible:outline-indigo-500 text-white"
                disabled={loading}
              >
                Se connecter
              </Button>
            </div>
          </form>

          <p className="mt-10 text-center text-sm text-gray-400">
            Pas encore membre ? Contactez nous :{" "}
            <a
              href="mailto: conctact@oditum.fr"
              className="font-semibold leading-6 text-indigo-400 hover:text-indigo-300"
            >
              conctact@oditum.fr
            </a>
          </p>
        </div>
      </div>
    </>
  );
};

export default Connexion;
