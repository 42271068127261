import React, { useState, useEffect } from "react";
import { useAuth } from "../contexts/AuthContext";
import { modifyUserById, uploadProfilePicture } from "../services/userServices";
import SuccessAlert from "./alerts/successalert";
import { useNavigate } from "react-router-dom";

export default function ProfileModifyForm() {
  const { currentUser, refreshUserData } = useAuth(); // Import refreshUserData
  const [formData, setFormData] = useState({
    name: "",
    firstname: "",
    fonction: "",
    description: "",
  });
  const [profilePicture, setProfilePicture] = useState(null);
  const [preview, setPreview] = useState(currentUser.profilePictureUrl);
  const [isCreated, setIsCreated] = useState(false);

  const navigate = useNavigate();

  const handleProfilePictureChange = (event) => {
    const file = event.target.files[0];
    setProfilePicture(file);

    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const updatedData = {
      name: event.target.name.value,
      firstname: event.target.firstname.value,
      fonction: event.target.fonction.value,
      description: event.target.description.value,
    };

    try {
      await modifyUserById(currentUser.uid, updatedData);

      // If a new profile picture was uploaded, handle the upload
      if (profilePicture) {
        await uploadProfilePicture(currentUser.uid, profilePicture);
      }

      setIsCreated(true);
      // Obliger pour que la photo soit update en front sans raffarichir manuellement
      window.location.assign("/profile");
    } catch (error) {
      console.error("Error updating profile: ", error);
      alert("Failed to update profile.");
    }
  };

  return (
    <div className="isolate bg-gray-900 px-6 py-10 sm:py-10 lg:px-8">
      {isCreated && (
        <SuccessAlert
          onDismiss={() => setIsCreated(false)}
          text="Profile modifié avec succès !"
        />
      )}
      <div className="mx-auto max-w-2xl text-center">
        <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
          Modifier votre profil
        </h2>
        <div className="w-full flex justify-center">
          <img
            src={preview}
            className="shadow-xl rounded-full border-none mt-10 w-[150px] h-[150px] object-cover"
            alt="Profile"
          />
        </div>
      </div>
      <form className="mx-auto mt-10 max-w-xl sm:mt-10" onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
          <div className="sm:col-span-2">
            <label
              htmlFor="profilePicture"
              className="block text-sm font-semibold leading-6 text-white"
            >
              Changer la photo de profil
            </label>
            <div className="mt-2.5">
              <input
                type="file"
                name="profilePicture"
                id="profilePicture"
                accept="image/*"
                className="block w-full text-gray-900 file:bg-indigo-600 file:text-white file:px-3 file:py-2 file:rounded-md"
                onChange={handleProfilePictureChange}
              />
            </div>
          </div>
          <div className="sm:col-span-1">
            <label
              htmlFor="name"
              className="block text-sm font-semibold leading-6 text-white"
            >
              Nom
            </label>
            <div className="mt-2.5">
              <input
                type="text"
                name="name"
                id="name"
                autoComplete="organization"
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                defaultValue={currentUser.name}
              />
            </div>
          </div>
          <div className="sm:col-span-1">
            <label
              htmlFor="firstname"
              className="block text-sm font-semibold leading-6 text-white"
            >
              Prénom
            </label>
            <div className="mt-2.5">
              <input
                type="text"
                name="firstname"
                id="firstname"
                autoComplete="organization"
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                defaultValue={currentUser.firstname}
              />
            </div>
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor="fonction"
              className="block text-sm font-semibold leading-6 text-white"
            >
              Rôle
            </label>
            <div className="mt-2.5">
              <input
                type="text"
                name="fonction"
                id="fonction"
                autoComplete="organization"
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                defaultValue={currentUser.fonction}
              />
            </div>
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor="description"
              className="block text-sm font-semibold leading-6 text-white"
            >
              Description
            </label>
            <div className="mt-2.5">
              <input
                type="text"
                name="description"
                id="description"
                autoComplete="organization"
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                defaultValue={currentUser.description}
              />
            </div>
          </div>
        </div>
        <div className="mt-10">
          <button
            type="submit"
            className="block w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Modifier
          </button>
        </div>
      </form>
    </div>
  );
}
