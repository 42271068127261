import { React, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../../assets/styles/quill.custom.css";

export default function Input({ data, onChange }) {
  const [value, setValue] = useState("");
  // Temporary fix to make all <th> elements white
  useEffect(() => {
    const thElements = document.querySelectorAll("th");
    thElements.forEach((th) => {
      th.style.color = "white";
    });
  }, []);

  switch (data.type) {
    // DATE
    case "date":
      return (
        <div className={data ? data.class : ""}>
          <label
            htmlFor="date"
            className="block text-sm font-medium text-white"
          >
            {data ? data.label : "Label"}
          </label>
          <div className="mt-1">
            <input
              type={data ? data.type : "text"}
              name={data ? data.name : "name"}
              id={data ? data.uid : "id"}
              onChange={(e) => onChange(e.target.value)}
              value={data ? data.value : ""}
              className="block w-full bg-gray-800 text-white focus:ring-gray-300 focus:border-indigo-500 min-w-0 rounded-md sm:text-sm border-gray-700"
              placeholder={data ? data.placeholder : "Placeholder"}
            />
          </div>
          <Markdown
            className="mt-2 text-sm text-gray-500 display-linebreak"
            id="date-description"
          >
            {data ? data.description : "Description"}
          </Markdown>
        </div>
      );

    // Text
    case "text":
      return (
        <div className={data ? data.class : ""}>
          <label
            htmlFor=""
            className="block text-sm font-medium text-white display-linebreak"
          >
            {data ? data.label : "Label"}
          </label>
          <div className="mt-1">
            <input
              type={data ? data.type : "text"}
              name={data ? data.name : "name"}
              id={data ? data.uid : "id"}
              value={data ? data.value : ""}
              onChange={(e) => onChange(e.target.value)}
              className="block w-full bg-gray-800 text-white focus:ring-gray-300 focus:border-indigo-500 min-w-0 rounded-md sm:text-sm border-gray-700"
              placeholder={data ? data.placeholder : "Placeholder"}
            />
          </div>
          <p
            className="mt-2 text-sm text-gray-500 display-linebreak"
            id="description"
          >
            <Markdown
              className="mt-2 text-sm text-gray-500 display-linebreak"
              id="description"
            >
              {data ? data.description : "Description"}
            </Markdown>
          </p>
        </div>
      );

    // Textarea
    case "textarea":
      return (
        <div className={data ? "min-w-full" : "min-w-full"}>
          <Markdown
            htmlFor=""
            className="block text-sm font-medium text-white w-full prose max-w-none"
            remarkPlugins={[remarkGfm]}
          >
            {data ? data.label : "Label"}
          </Markdown>
          <div className="mt-5">
            {/* <textarea
              rows={10}
              name={data ? data.name : "name"}
              id={data ? data.uid : "id"}
              value={data ? data.value : ""}
              onChange={(e) => onChange(e.target.value)}
              className="block w-full bg-gray-800 text-white focus:ring-gray-300 focus:border-indigo-500 min-w-0 rounded-md sm:text-sm border-gray-700"
              placeholder={data ? data.placeholder : "Placeholder"}
            /> */}
            <ReactQuill value={data.value} onChange={onChange} />
          </div>
          <Markdown
            className="mt-2 text-sm text-gray-500 display-linebreak"
            id="description"
            remarkPlugins={[remarkGfm]}
          >
            {data ? data.description : "Description"}
          </Markdown>
        </div>
      );

    // Radio button
    case "radio":
      return (
        <fieldset className={data ? data.class : ""}>
          <legend className="block text-sm font-medium text-white">
            <Markdown
              htmlFor=""
              className="block text-sm font-medium text-white prose w-full"
            >
              {data ? data.label : "Label"}
            </Markdown>
          </legend>
          <div className="mt-4 space-y-4">
            {data
              ? data.options.map((option, index) => (
                  <div key={index} className="flex items-center">
                    <input
                      id={data ? data.uid : "id"}
                      name={data.name}
                      value={option.value}
                      checked={data.value === option.value}
                      onChange={(e) => onChange(e.target.value)}
                      type="radio"
                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                    />
                    <label
                      htmlFor={option.id}
                      className="ml-3 block text-sm font-medium text-white"
                    >
                      <Markdown
                        className="text-sm text-white display-linebreak"
                        id="option"
                      >
                        {option.label}
                      </Markdown>
                    </label>
                  </div>
                ))
              : ""}
          </div>
        </fieldset>
      );

    // Checkbox
    case "checkbox":
      return (
        <fieldset className={data ? data.class : ""}>
          <legend className="block text-sm font-medium text-white">
            <Markdown
              htmlFor=""
              className="block text-sm font-medium text-white prose w-full"
            >
              {data ? data.label : "Label"}
            </Markdown>
          </legend>
          <div className="mt-4 space-y-4">
            {data
              ? data.options.map((option, index) => (
                  <div key={index} className="flex items-center">
                    <input
                      id={data ? data.uid : "id"}
                      name={data.name}
                      value={option.value}
                      onChange={(e) => onChange(option.value, data.type)}
                      checked={data.value.includes(option.value)}
                      type="checkbox"
                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                    />
                    <label
                      htmlFor={option.id}
                      className="ml-3 block text-sm font-medium text-white"
                    >
                      <Markdown
                        className="text-sm text-white display-linebreak"
                        id="option"
                      >
                        {option.label}
                      </Markdown>
                    </label>
                  </div>
                ))
              : ""}
          </div>
        </fieldset>
      );

    // Select
    case "select":
      return (
        <div className={data ? data.class : ""}>
          <label
            htmlFor="email"
            className="block text-sm font-medium text-white"
          >
            {data ? data.label : "Label"}
          </label>
          <div className="mt-1">
            <select
              id={data ? data.uid : "id"}
              name={data ? data.name : "name"}
              onChange={(e) => onChange(e.target.value)}
              value={data ? data.value : ""}
              className="block w-full bg-gray-800 text-white focus:ring-gray-300 focus:border-indigo-500 min-w-0 rounded-md sm:text-sm border-gray-700"
            >
              {data
                ? data.options.map((option, index) => (
                    <option key={index} value={option.value}>
                      {option.label}
                    </option>
                  ))
                : ""}
            </select>
          </div>
          <p
            className="mt-2 text-sm text-gray-500 display-linebreak"
            id="email-description"
          >
            {data ? data.description : "Description"}
          </p>
        </div>
      );

    default:
      return <p>Default</p>;
  }
}

Input.propTypes = {
  data: PropTypes.object,
  onChange: PropTypes.func,
};
