import { db } from "../config/firebase";
import {
  collection,
  getDocs,
  getDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { arrayRemove, arrayUnion } from "firebase/firestore";

import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "../config/firebase";

import { uploadBytes } from "firebase/storage";

const getAllUsers = async (currentUser) => {
  try {
    const querySnapshot = await getDocs(collection(db, "users"));
    const usersList = [];
    for (const document of querySnapshot.docs) {
      // Renamed 'doc' to 'document'
      const userData = document.data();
      const storage = getStorage();
      const profilePicRef = ref(storage, `profilePictures/${document.id}.jpeg`);

      if (
        currentUser.organisation_id &&
        userData.organisation_id &&
        currentUser.organisation_id == userData.organisation_id
      ) {
        try {
          const profilePicUrl = await getDownloadURL(profilePicRef);
          userData.profilePicUrl = profilePicUrl;
        } catch (error) {
          console.warn("Error fetching profile picture: ", error);
          const imageRef = ref(storage, `profilePictures/default.png`);
          const url = await getDownloadURL(imageRef);
          userData.profilePicUrl = url; // Handle missing profile picture case
        }

        // Get organisation name from organisation ID
        if (userData.organisation_id) {
          const organisationRef = doc(
            db,
            "organisation",
            userData.organisation_id,
          );
          const organisationDoc = await getDoc(organisationRef);
          if (organisationDoc.exists()) {
            userData.organisationName = organisationDoc.data().name;
          }
        }

        usersList.push({ id: document.id, ...userData });
      }
    }
    return usersList;
  } catch (e) {
    console.error("Error getting documents: ", e);
    throw e; // rethrow the error for handling in the component
  }
};

// Get users by IDs
export const getUsersByIds = async (userIds) => {
  try {
    const usersList = [];
    for (const userId of userIds) {
      const userRef = doc(db, "users", userId);
      const userDoc = await getDoc(userRef);
      if (userDoc.exists()) {
        const userData = userDoc.data();
        const storage = getStorage();
        const profilePicRef = ref(storage, `profilePictures/${userId}.jpeg`);

        try {
          const profilePicUrl = await getDownloadURL(profilePicRef);
          userData.profilePicUrl = profilePicUrl;
        } catch (error) {
          console.warn("Error fetching profile picture: ", error);
          const imageRef = ref(storage, `profilePictures/default.png`);
          const url = await getDownloadURL(imageRef);
          userData.profilePicUrl = url; // Handle missing profile picture case
        }

        usersList.push({ id: userId, ...userData });
      }
    }
    return usersList;
  } catch (e) {
    console.error("Error getting documents: ", e);
    throw e; // rethrow the error for handling in the component
  }
};

// Create firebase user
export const createUser = async (userData) => {
  try {
    console.log(userData);
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      userData.email,
      "Temporaire",
    );
    console.log(userCredential.user);
  } catch (error) {
    throw new Error(error.message);
  }
};

const modifyUserById = async (userId, newData) => {
  try {
    const userRef = doc(db, "users", userId);
    await updateDoc(userRef, newData);
    console.log(`User ${userId} has been updated.`);
  } catch (e) {
    console.error("Error updating user: ", e);
    throw e;
  }
};

// Update profile picture. Replace the existing profile picture with the new one with the user ID as the file name and .jpeg extension
export const uploadProfilePicture = async (userId, file) => {
  const storage = getStorage();
  const storageRef = ref(storage, `profilePictures/${userId}.jpeg`);
  try {
    const snapshot = await uploadBytes(storageRef, file);
    console.log("Uploaded a blob or file!", snapshot);
  } catch (error) {
    console.error("Error uploading file: ", error);
    throw error;
  }
};

// Fonction pour ajouter ou retirer un client des favoris de l'utilisateur
const updateFavorite = async (userId, clientId) => {
  try {
    const userRef = doc(db, "users", userId);

    // Récupérer l'utilisateur
    const userDoc = await getDoc(userRef);
    if (!userDoc.exists()) {
      throw new Error(`User with ID ${userId} does not exist.`);
    }

    const userData = userDoc.data();
    const favoriteClients = userData.favoriteClients || [];

    // Vérifier si le client est déjà dans la liste des favoris
    if (favoriteClients.includes(clientId)) {
      // Si oui, le retirer
      await updateDoc(userRef, {
        favoriteClients: arrayRemove(clientId),
      });
      console.log(`Client ${clientId} has been removed from favorites.`);
    } else {
      // Sinon, l'ajouter
      await updateDoc(userRef, {
        favoriteClients: arrayUnion(clientId),
      });
      console.log(`Client ${clientId} has been added to favorites.`);
    }
  } catch (e) {
    console.error("Error updating favorite clients: ", e);
    throw e;
  }
};

// Fonction pour ajouter ou retirer un engagement des favoris de l'utilisateur
const updateFavoriteEngagement = async (userId, engagementId) => {
  try {
    const userRef = doc(db, "users", userId);

    // Récupérer l'utilisateur
    const userDoc = await getDoc(userRef);
    if (!userDoc.exists()) {
      throw new Error(`User with ID ${userId} does not exist.`);
    }

    const userData = userDoc.data();
    const favoriteEngagements = userData.favoriteEngagements || [];

    // Vérifier si l'engagement est déjà dans la liste des favoris
    if (favoriteEngagements.includes(engagementId)) {
      // Si oui, le retirer
      await updateDoc(userRef, {
        favoriteEngagements: arrayRemove(engagementId),
      });
      console.log(
        `Engagement ${engagementId} has been removed from favorites.`,
      );
    } else {
      // Sinon, l'ajouter
      await updateDoc(userRef, {
        favoriteEngagements: arrayUnion(engagementId),
      });
      console.log(`Engagement ${engagementId} has been added to favorites.`);
    }
  } catch (e) {
    console.error("Error updating favorite engagements: ", e);
    throw e;
  }
};

// Ajouter un utilisateur à un engagement
const addUserToEngagement = async (userId, engagementId) => {
  try {
    const engagementRef = doc(db, "engagements", engagementId);

    // Mise à jour du document de l'engagement pour ajouter l'utilisateur à l'array des participants
    await updateDoc(engagementRef, {
      authorized_users_ids: arrayUnion(userId),
    });

    console.log(`User ${userId} added to engagement ${engagementId}`);
  } catch (e) {
    console.error("Error adding user to engagement: ", e);
    throw e;
  }
};

// Retirer un utilisateur d'un engagement
const removeUserFromEngagement = async (userId, engagementId) => {
  try {
    const engagementRef = doc(db, "engagements", engagementId);

    // Mise à jour du document de l'engagement pour retirer l'utilisateur de l'array des participants
    await updateDoc(engagementRef, {
      authorized_users_ids: arrayRemove(userId),
    });

    console.log(`User ${userId} removed from engagement ${engagementId}`);
  } catch (e) {
    console.error("Error removing user from engagement: ", e);
    throw e;
  }
};

export {
  getAllUsers,
  modifyUserById,
  updateFavorite,
  updateFavoriteEngagement,
  addUserToEngagement,
  removeUserFromEngagement,
};
