import React, { useState } from "react";

import { Navbar } from "../components";
import DashboardSection from "../components/dashboard/dashboardsection";

const Dashboard = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div className="h-full">
      {/* LEFT PANEL */}
      <Navbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* MAIN PANEL */}
      <div className="xl:pl-72 h-full">
        <DashboardSection />
      </div>
    </div>
  );
};

export default Dashboard;
