import { React, useEffect } from "react";
import { XCircleIcon, XIcon } from "@heroicons/react/solid";
import PropTypes from "prop-types";

export default function ErrorAlert({
  text,
  position = "top",
  timer = 5000,
  onDismiss,
}) {
  useEffect(() => {
    const timerWait = setTimeout(onDismiss, timer); // Déclenche onDismiss après 5 secondes
    return () => clearTimeout(timerWait); // Nettoie le timer si le composant est démonté avant 5 secondes
  }, [onDismiss]);
  if (position === "top") {
    return (
      <div className="fixed inset-x-0 top-5 mt-12 rounded-md bg-red-50 p-4 w-3/4 mx-auto z-50">
        <div className="flex">
          <div className="flex-shrink-0">
            <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">{text}</h3>
          </div>
          <div className="ml-auto pl-3">
            <div className="-mx-1.5 -my-1.5">
              <button
                type="button"
                className="inline-flex bg-red-50 rounded-md p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-50 focus:ring-red-600"
                onClick={onDismiss}
              >
                <span className="sr-only">Dismiss</span>
                <XIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="fixed inset-x-0 bottom-5 mb-5 rounded-md bg-red-50 p-4 max-w-md mx-auto z-50">
        <div className="flex">
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">{text}</h3>
          </div>
          <div className="flex-shrink-0">
            <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
          </div>
        </div>
      </div>
    );
  }
}

// Props
ErrorAlert.propTypes = {
  text: PropTypes.string.isRequired,
  position: PropTypes.string,
  timer: PropTypes.number,
  onDismiss: PropTypes.func,
};
