import { React, useState, useEffect } from "react";
import SuccessAlert from "../alerts/successalert";
import PropTypes from "prop-types";
import { updateEngagement } from "../../services/engagementServices";
import {
  getUsersByIds,
  getAllUsers,
  addUserToEngagement,
  removeUserFromEngagement,
} from "../../services/userServices";
import { Listbox, Transition } from "@headlessui/react";
import { useAuth } from "../../contexts/AuthContext";
import { Treadmill } from "../loading/treadmill";

export default function parameterscreen({ engagementData }) {
  const [isUpdated, setIsUpdated] = useState(false);
  const [formData, setFormData] = useState({
    description: (engagementData && engagementData.description) || "",
    state: (engagementData && engagementData.state) || "",
  });
  const [authorizedUsers, setAuthorizedUsers] = useState([]);
  const [selectedPersons, setSelectedPersons] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [usersListbox, setUsersListbox] = useState([]);
  const [isDeleted, setIsDeleted] = useState(false);
  const currentUser = useAuth().currentUser;
  const [isLoaded, setIsLoaded] = useState(false);
  const [isAdding, setIsAdding] = useState(false);

  const customStyle = {
    height: "62px",
  };

  useEffect(() => {
    const fetchData = async () => {
      const result = await getUsersByIds(engagementData.authorized_users_ids);
      const usersListbox = await getAllUsers(currentUser);
      setAuthorizedUsers(result);
      setUsersListbox(usersListbox);
      setIsLoaded(true);
    };
    fetchData();
  }, [engagementData.authorized_users_ids]);

  function isSelected(value) {
    return selectedPersons.find((el) => el === value.id) ? true : false;
  }

  function handleSelect(value) {
    if (!isSelected(value)) {
      setSelectedPersons([...selectedPersons, value.id]);
    } else {
      handleDeselect(value.id);
    }
    setIsOpen(true);
  }

  function handleDeselect(valueId) {
    const selectedPersonsUpdated = selectedPersons.filter(
      (id) => id !== valueId,
    );
    setSelectedPersons(selectedPersonsUpdated);
    setIsOpen(true);
  }

  const handleDeleteUser = async (userId) => {
    try {
      // Call service to delete user from the team
      await removeUserFromEngagement(userId, engagementData.id);

      // Filter out the deleted user from the local state
      setAuthorizedUsers(authorizedUsers.filter((user) => user.id !== userId));
    } catch (error) {
      console.error("Error deleting user:", error);
      // Handle error (e.g., show an error message to the user)
    }
  };

  const getNameById = (id) => {
    const user = usersListbox.find((user) => user.id === id);
    return user ? user.name : null;
  };

  const getFirstnameById = (id) => {
    const user = usersListbox.find((user) => user.id === id);
    return user ? user.firstname : null;
  };

  const handleAddUser = async () => {
    if (selectedPersons.length > 0) {
      setIsAdding(true);
      try {
        await Promise.all(
          selectedPersons.map((person) =>
            addUserToEngagement(person, engagementData.id),
          ),
        );
        window.location.reload();
      } catch (error) {
        console.error("Error adding user:", error);
        // Handle error (e.g., show an error message to the user)
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDismiss = () => {
    setIsUpdated(false);
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      await updateEngagement(engagementData.id, formData);
      setIsUpdated(true);
    } catch (e) {
      console.error(e);
    }
  };

  if (!isLoaded) {
    return (
      <div className="flex justify-center items-start mt-24 h-screen">
        <Treadmill />
      </div>
    );
  }

  return (
    <>
      {/* Alterte de mise à jour */}
      {isUpdated && (
        <div className="fixed top-10 right-20">
          <SuccessAlert
            onDismiss={handleDismiss}
            text="Vos modification ont été enregistrées avec succès."
          />
        </div>
      )}

      {/* Formulaire */}
      <form
        className="w-full mt-10 flex flex-col justify-center items-center"
        onSubmit={handleSubmit}
      >
        {/* Inputs */}
        <div className="w-2/3 m-4">
          {/* Description */}
          <div className="w-full">
            <label
              htmlFor=""
              className="block text-sm font-medium text-white display-linebreak"
            >
              Description de l&apos;engagement
            </label>
            <div className="mt-1">
              <textarea
                type="text"
                name="description"
                id="description"
                value={formData.description ? formData.description : ""}
                className="block w-full bg-gray-800 text-white focus:ring-gray-300 focus:border-indigo-500 min-w-0 rounded-md sm:text-sm border-gray-700"
                placeholder="Changer la description de l'engagement"
                onChange={handleChange}
              />
            </div>
            <p
              className="mt-2 text-sm text-gray-500 display-linebreak"
              id="email-description"
            >
              {/* Description */}
            </p>
          </div>
          {/* Selection de l'état de l'engagement */}
          <div className="w-full mt-12">
            <label
              htmlFor=""
              className="block text-sm font-medium text-white display-linebreak"
            >
              État de l&apos;engagement
            </label>
            <div className="mt-1">
              <select
                name="state"
                id="state"
                className="block w-full bg-gray-800 text-white focus:ring-gray-300 focus:border-indigo-500 min-w-0 rounded-md sm:text-sm border-gray-700"
                value={formData.state ? formData.state : ""}
                onChange={handleChange}
              >
                <option value="">-</option>
                <option value="Brouillon">Brouillon</option>
                <option value="En cours">En cours</option>
                <option value="En attente">En attente</option>
                <option value="Signé">Signé</option>
                <option value="Suspendu">Suspendu</option>
                <option value="Archivé">Archivé</option>
              </select>
            </div>
            <p
              className="mt-2 text-sm text-gray-500 display-linebreak"
              id="email-description"
            >
              {/* Description */}
            </p>
          </div>
        </div>
        {/* Bouton save */}
        <div className="fixed bottom-10 right-20">
          <button
            type="submit"
            className="block w-32 rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Sauvegarder
          </button>
        </div>
      </form>
      <div className="w-3/5 mx-auto my-10">
        {isDeleted && (
          <SuccessAlert
            onDismiss={handleDismiss}
            text="Equipe supprimé avec succès !"
          />
        )}
        <h2 className="text-center text-white text-2xl font-semibold mt-3 mb-3">
          {"Utilisateurs autorisés"}
        </h2>
        <p className="text-center text-white text-sm font-normal leading-normal">
          {"Lsite des utilisateurs autorisés à accéder à cet engagement"}
        </p>
        <ul role="list" className="divide-y divide-gray-100 mt-5">
          {authorizedUsers.map((person) => (
            <li key={person.email} className="flex justify-between py-5">
              <div className="flex min-w-0 gap-x-4">
                <img
                  className="h-12 w-12 flex-none rounded-full bg-gray-50"
                  src={person.profilePicUrl}
                  alt=""
                />
                <div className="min-w-0 flex-auto">
                  <p className="text-sm font-semibold leading-6 text-white">
                    {person.name + " " + person.firstname}
                  </p>
                  <p className="mt-1 truncate text-xs leading-5 text-gray-500">
                    {person.email}
                  </p>
                </div>
              </div>
              <div className="flex gap-x-6">
                <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
                  <p className="text-sm leading-6 text-white">
                    {person.fonction}
                  </p>
                </div>
                {person.id != engagementData.creator_id && (
                  <button
                    onClick={() => handleDeleteUser(person.id)}
                    className="bg-black/10 hover:bg-red-600 text-white font-bold py-2 px-4 border border-gray-400 rounded"
                  >
                    X
                  </button>
                )}
              </div>
            </li>
          ))}
        </ul>
        <div className="flex flex-wrap w-full justify-between content-end mt-3">
          <div className="flex justify-between content-end">
            <Listbox
              as="div"
              className="w-56"
              value={selectedPersons}
              onChange={(value) => handleSelect(value)}
              open={isOpen}
            >
              {() => (
                <>
                  <Listbox.Label className="block text-sm font-semibold leading-6 text-white">
                    Membres de l&apos;équipe
                  </Listbox.Label>
                  <div className="relative">
                    <span className="inline-block w-full rounded-md shadow-sm">
                      <Listbox.Button
                        className="cursor-default relative w-full rounded-md border border-gray-300 bg-white pl-3 pr-10 py-2 text-left focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                        onClick={() => setIsOpen(!isOpen)}
                        open={isOpen}
                      >
                        <span className="block truncate">
                          {selectedPersons.length < 1
                            ? "Select persons"
                            : `Selected persons (${selectedPersons.length})`}
                        </span>
                        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                          <svg
                            className="h-5 w-5 text-gray-400"
                            viewBox="0 0 20 20"
                            fill="none"
                            stroke="currentColor"
                          >
                            <path
                              d="M7 7l3-3 3 3m0 6l-3 3-3-3"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </span>
                      </Listbox.Button>
                    </span>

                    <Transition
                      unmount={false}
                      show={isOpen}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                      className="absolute mt-1 w-full rounded-md bg-white shadow-lg"
                    >
                      <Listbox.Options
                        static
                        className="max-h-60 rounded-md py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5"
                      >
                        {usersListbox.map((person) => {
                          const selected = isSelected(person);
                          return (
                            <Listbox.Option key={person.id} value={person}>
                              {({ active }) => (
                                <div
                                  className={`${
                                    active
                                      ? "text-white bg-blue-600 flex items-center"
                                      : "text-gray-900 flex items-center"
                                  } cursor-default select-none relative py-2 pl-8 pr-4 flex items-center`}
                                >
                                  <img
                                    src={person.profilePicUrl}
                                    alt=""
                                    className="h-5 w-5 flex-shrink-0 rounded-full"
                                  />
                                  <span
                                    className={`${
                                      selected
                                        ? "font-semibold ml-3"
                                        : "font-normal ml-3"
                                    } block truncate`}
                                  >
                                    {person.name + " " + person.firstname}
                                  </span>
                                  {selected && (
                                    <span
                                      className={`${
                                        active ? "text-white" : "text-blue-600"
                                      } absolute inset-y-0 left-0 flex items-center pl-1.5`}
                                    >
                                      <svg
                                        className="h-5 w-5"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                          clipRule="evenodd"
                                        />
                                      </svg>
                                    </span>
                                  )}
                                </div>
                              )}
                            </Listbox.Option>
                          );
                        })}
                      </Listbox.Options>
                    </Transition>
                    <div className="pt-1 text-sm text-gray-400">
                      {selectedPersons.length > 0 && (
                        <>
                          Selected persons:{" "}
                          {selectedPersons.map(
                            (personSelected) =>
                              getFirstnameById(personSelected) +
                              " " +
                              getNameById(personSelected) +
                              ", ",
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </>
              )}
            </Listbox>
            <div
              className="flex items-end justify-center ml-5"
              style={customStyle}
            >
              {!isAdding ? (
                <button
                  onClick={() => handleAddUser()}
                  className="flex-none bg-black/10 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded"
                >
                  Ajouter
                </button>
              ) : (
                <div
                  type="button"
                  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <Treadmill color="black" size="40" speed="0.5" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

// Prop
parameterscreen.propTypes = {
  engagementData: PropTypes.object.isRequired,
};
