import { React, useEffect, useState } from "react";

import { useAuth } from "../contexts/AuthContext";
import { Treadmill } from "./loading/treadmill";

// import "bootstrap/dist/css/bootstrap.min.css";

const ProfileSection = ({}) => {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Treadmill />
      </div>
    );
  }

  return (
    <div className="mt-20">
      <div className="max-w-md mx-auto md:max-w-2xl mt-6 min-w-0 break-words bg-black/10 w-full mb-6 shadow-lg rounded-xl mt-16">
        <div className="px-6">
          <div className="flex flex-wrap justify-center">
            <div className="w-full flex justify-center">
              <div className="w-full flex justify-center">
                <img
                  src={currentUser.profilePictureUrl}
                  className="shadow-xl rounded-full align-middle border-none absolute -m-16 -ml-20 lg:-ml-16 w-[150px] h-[150px] object-cover"
                />
              </div>
            </div>
            <div className="w-full text-center mt-20">
              <div className="flex justify-center lg:pt-4 pt-8 pb-0">
                <div className="p-3 text-center">
                  <span className="text-xl font-bold block uppercase tracking-wide text-white">
                    4
                  </span>
                  <span className="text-sm text-slate-400">Engagements</span>
                </div>
                <div className="p-3 text-center">
                  <span className="text-xl font-bold block uppercase tracking-wide text-white">
                    2
                  </span>
                  <span className="text-sm text-slate-400">Equipes</span>
                </div>

                <div className="p-3 text-center">
                  <span className="text-xl font-bold block uppercase tracking-wide text-white">
                    564
                  </span>
                  <span className="text-sm text-slate-400">
                    Dossiers signés
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center mt-2">
            <h3 className="text-2xl text-white font-bold leading-normal mb-1">
              {currentUser.name} {currentUser.firstname}
            </h3>
            <div className="text-xs mt-0 mb-2 text-slate-400 font-bold uppercase">
              <i className="fas fa-map-marker-alt text-slate-400 opacity-75"></i>
              {currentUser.fonction}
            </div>
          </div>
          <div className="mt-6 py-6 border-t border-slate-200 text-center">
            <div className="flex flex-wrap justify-center">
              <div className="w-full px-4">
                <p className="font-light leading-relaxed text-gray-400 mb-4">
                  {currentUser.description}
                </p>
                {/* CREATE */}
                <a href="profile/modify" className="">
                  <button className="bg-black/10 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded">
                    Modifier
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileSection;
