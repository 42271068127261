import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

export default function PrivateAdminRoute() {
  const { currentUser } = useAuth();

  return currentUser && currentUser.is_admin ? (
    <Outlet />
  ) : (
    <Navigate to="/connexion" />
  );
}
