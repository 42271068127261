import { React, useEffect } from "react";
import { CheckCircleIcon, XIcon } from "@heroicons/react/solid";
import PropTypes from "prop-types";

export default function SuccessAlert({ onDismiss, text }) {
  useEffect(() => {
    const timer = setTimeout(onDismiss, 5000); // Déclenche onDismiss après 5 secondes
    return () => clearTimeout(timer); // Nettoie le timer si le composant est démonté avant 5 secondes
  }, [onDismiss]);

  return (
    <div className="rounded-md bg-green-50 p-4 m-2">
      <div className="flex">
        <div className="flex-shrink-0">
          <CheckCircleIcon
            className="h-5 w-5 text-green-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <p className="text-sm font-medium text-green-800">{text}</p>
        </div>
        <div className="ml-auto pl-3">
          <div className="-mx-1.5 -my-1.5">
            <button
              type="button"
              className="inline-flex bg-green-50 rounded-md p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600"
              onClick={onDismiss}
            >
              <span className="sr-only">Dismiss</span>
              <XIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

SuccessAlert.propTypes = {
  onDismiss: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
};
