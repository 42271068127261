import React, { useContext, useState, useEffect } from "react";
import {
  auth,
  signInWithEmailAndPassword,
  db,
  storage,
} from "../config/firebase";
import { doc, getDoc } from "firebase/firestore";
import PropTypes from "prop-types";
import { ref, getDownloadURL } from "firebase/storage";

import { sendPasswordResetEmail } from "firebase/auth";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);

  // Function to login with email and password
  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  // Function to logout
  function logout() {
    return auth.signOut();
  }

  // Function to get the profile picture URL
  const getProfilePictureUrl = async (userId) => {
    const imagePath = `profilePictures/${userId}.jpeg`;
    const imageRef = ref(storage, imagePath);

    try {
      const url = await getDownloadURL(imageRef);
      return url;
    } catch (error) {
      const imageRef = ref(storage, `profilePictures/default.png`);
      const url = await getDownloadURL(imageRef);
      return url;
    }
  };

  useEffect(() => {
    // onAuthStateChanged returns an unsubscriber
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        // get user data from firestore
        const userDocRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userDocRef);
        const userData = userDoc.exists() ? userDoc.data() : null;
        const profilePictureUrl = await getProfilePictureUrl(user.uid);
        setCurrentUser({ ...user, ...userData, profilePictureUrl });
      } else {
        setCurrentUser(null);
      }
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    login,
    logout,
    resetPassword,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}

function resetPassword(email) {
  return sendPasswordResetEmail(auth, email);
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
