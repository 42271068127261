import { db } from "../config/firebase";
import {
  doc,
  getDoc,
  addDoc,
  updateDoc,
  arrayRemove,
  arrayUnion,
  deleteDoc,
} from "firebase/firestore";
import { collection, query, where, getDocs, orderBy } from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

const getAllTeams = async () => {
  try {
    const teamsRef = collection(db, "teams");
    const q = query(teamsRef, orderBy("teamName")); // Order by 'teamName' field
    const querySnapshot = await getDocs(q);
    const teamsList = [];

    for (const teamDoc of querySnapshot.docs) {
      const teamData = teamDoc.data();
      const storage = getStorage();

      if (teamData.members && teamData.members.length > 0) {
        const membersInfo = await Promise.all(
          teamData.members.map(async (userId) => {
            const userDocRef = doc(db, "users", userId);
            const userDocSnap = await getDoc(userDocRef);

            let userInfo = { id: userId };

            if (userDocSnap.exists()) {
              userInfo = { ...userDocSnap.data(), id: userId };

              // Attempt to get the download URL for the user's profile picture
              try {
                const profilePicRef = ref(
                  storage,
                  `profilePictures/${userId}.jpeg`,
                );
                const profilePicUrl = await getDownloadURL(profilePicRef);
                userInfo.profilePicture = profilePicUrl;
              } catch (error) {
                console.warn("Error fetching profile picture: ", error);
                const imageRef = ref(storage, `profilePictures/default.png`);
                const url = await getDownloadURL(imageRef);
                userInfo.profilePicture = url; // Handle missing profile picture case
                // If profile picture isn't found, leave userInfo without the profilePicture property
              }
            }

            return userInfo;
          }),
        );

        teamData.members = membersInfo; // Replace member IDs with detailed info including profile picture URL
      }

      teamsList.push({ id: teamDoc.id, ...teamData });
    }

    return teamsList;
  } catch (e) {
    console.error("Error getting documents: ", e);
    throw e; // rethrow the error for handling in the component
  }
};

const addTeam = async (teamData) => {
  try {
    const docRef = await addDoc(collection(db, "teams"), teamData);
    console.log("Document written with ID: ", docRef.id);
    return docRef.id; // return the document ID
  } catch (e) {
    console.error("Error adding document: ", e);
    throw e; // rethrow the error for handling in the component
  }
};

const getTeamById = async (teamId) => {
  try {
    const docRef = doc(db, "teams", teamId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return { id: docSnap.id, ...docSnap.data() };
    } else {
      console.log("No such document!");
      return null; // or handle as you see fit
    }
  } catch (e) {
    console.error("Error getting document: ", e);
    throw e; // rethrow the error for handling in the component
  }
};

const getAllUsersFromTeamId = async (teamId, currentUser) => {
  try {
    // Fetch the team document to get the user IDs
    const teamDocRef = doc(db, "teams", teamId);
    const teamDocSnap = await getDoc(teamDocRef);

    if (!teamDocSnap.exists()) {
      console.log("No such team!");
      return [];
    }

    const teamData = teamDocSnap.data();
    if (!teamData.members || teamData.members.length === 0) {
      console.log("No users in this team");
      return [];
    }

    const usersList = [];
    for (const userId of teamData.members) {
      const userDocRef = doc(db, "users", userId);
      const userDocSnap = await getDoc(userDocRef);

      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();
        const storage = getStorage();
        const profilePicRef = ref(storage, `profilePictures/${userId}.jpeg`);

        try {
          // Attempt to get the download URL for the user's profile picture
          const profilePicUrl = await getDownloadURL(profilePicRef);
          usersList.push({
            id: userId,
            ...userData,
            profilePicture: profilePicUrl,
          });
        } catch (error) {
          console.warn("Error fetching profile picture: ", error);
          const imageRef = ref(storage, `profilePictures/default.png`);
          const url = await getDownloadURL(imageRef);
          usersList.push({ id: userId, ...userData, profilePicture: url });
        }
      }
    }

    return usersList;
  } catch (e) {
    console.error("Error getting team or users: ", e);
    throw e;
  }
};

const deleteUserFromTeam = async (teamId, userId) => {
  try {
    const teamDocRef = doc(db, "teams", teamId);
    const userRef = { id: userId }; // Assuming the members array contains objects with id properties

    // Update the team document to remove the user from the members array
    await updateDoc(teamDocRef, {
      members: arrayRemove(userId),
    });

    console.log(`User ${userId} removed from team ${teamId}`);
  } catch (e) {
    console.error("Error removing user from team: ", e);
    throw e;
  }
};

const addUserToTeam = async (teamId, userId) => {
  try {
    const teamDocRef = doc(db, "teams", teamId);

    // Mise à jour du document de l'équipe pour ajouter l'utilisateur à l'array des membres
    await updateDoc(teamDocRef, {
      members: arrayUnion(userId),
    });

    console.log(`User ${userId} added to team ${teamId}`);
  } catch (e) {
    console.error("Error adding user to team: ", e);
    throw e;
  }
};

const deleteTeam = async (teamId) => {
  try {
    const docRef = doc(db, "teams", teamId);
    await deleteDoc(docRef);
    console.log("Document deleted with ID: ", teamId);
  } catch (e) {
    console.error("Error deleting document: ", e);
    throw e;
  }
};

const teamNames = [
  "Équipe Alpha",
  "Équipe Bravo",
  "Équipe Charlie",
  "Équipe Delta",
  "Équipe Echo",
  "Équipe Foxtrot",
  "Équipe Golf",
  "Équipe Hotel",
  "Équipe India",
  "Équipe Juliet",
];

const descriptions = [
  "Spécialisée dans la recherche et développement",
  "Orientée vers les nouvelles technologies",
  "En charge des opérations sur le terrain",
  "Dédiée aux stratégies de marché",
  "Focus sur le développement durable",
  "Responsable des partenariats stratégiques",
  "Concentrée sur l'innovation produit",
  "À la pointe de la transformation digitale",
  "Expertise dans la logistique et la chaine d'approvisionnement",
  "Engagée dans la satisfaction client",
];

const members = [
  "Nm1H3t6IHfNuS4k1984bwdByNBw2",
  "OE0uEsaHUyT6fFO6rRftLCeVaLk2",
  "hbMBUdVg6AcrZ3EdlUU4H9rvU6p2",
];

async function createTeams() {
  try {
    const createdTeams = [];

    for (let i = 0; i < teamNames.length; i++) {
      const newTeam = {
        createdAt: new Date().toISOString(), // ou votre propre format de date
        description: descriptions[i], // Utilisez l'indice pour associer chaque description
        members: members,
        teamName: teamNames[i],
      };

      const docRef = await addDoc(collection(db, "teams"), newTeam);
      console.log(
        "Équipe créée avec l'ID: ",
        docRef.id,
        " et la description: ",
        descriptions[i],
      );
      createdTeams.push(docRef);
    }

    return createdTeams;
  } catch (error) {
    console.error("Erreur lors de la création des équipes:", error);
    throw error;
  }
}

// Utilisation de la fonction pour créer les équipes
// createTeams().then((createdTeams) => {
//   console.log(`${createdTeams.length} équipes ont été créées.`);
// });

async function deleteAllTeams() {
  const teamsCollectionRef = collection(db, "teams");
  const snapshot = await getDocs(teamsCollectionRef);

  // Créer un tableau de promesses pour chaque suppression de document
  const deletePromises = snapshot.docs.map((docSnapshot) =>
    deleteDoc(doc(db, "teams", docSnapshot.id)),
  );

  // Exécuter toutes les suppressions en parallèle
  await Promise.all(deletePromises);
  console.log("Tous les documents de la collection 'teams' ont été supprimés.");
}

export {
  addTeam,
  getAllTeams,
  getTeamById,
  getAllUsersFromTeamId,
  deleteUserFromTeam,
  addUserToTeam,
  deleteAllTeams,
  deleteTeam,
};
